import request from "@/utils/request";

export function getSubjectsList(data) {
  return request({
    method: "POST",
    url: "lesson/list-lesson",
    data: data
  });
}

export function getSubjects() {
  return request({
    method: "GET",
    url: "lesson/lesson-master"
  });
}

export function submitSubjects(data) {
  return request({
    method: "POST",
    url: "lesson/add-lesson",
    data: data
  });
}

export function submitEditSubject(data) {
  return request({
    method: "POST",
    url: "lesson/update-lesson",
    data: data
  });
}

export function deleteSubjects(data) {
  return request({
    method: "POST",
    url: "lesson/delete-lesson",
    data: data
  });
}

export function listMaster(data) {
  return request({
    method: "GET",
    url: "lesson/lesson-master",
    data: data
  });
}

export function addedMaster(data) {
  return request({
    method: "POST",
    url: "lesson/add-master",
    data: data
  });
}

export function deleteMaster(data) {
  return request({
    method: "POST",
    url: "lesson/delete-master",
    data: data
  });
}

export function updateMaster(data) {
  return request({
    method: "POST",
    url: "lesson/update-master",
    data: data
  });
}
