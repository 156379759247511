<template>
  <v-card class="pa-2" flat>
    <v-card-text>
      <div v-if="$vuetify.breakpoint.mdAndDown" class="mb-5 align-center">
        <div class="d-flex align-center">
          <v-btn color="primary" depressed @click="sheet = !sheet">
            <v-icon>mdi-filter-outline</v-icon>
          </v-btn>
          <v-spacer />
          <v-speed-dial
            v-model="menuSubject"
            direction="bottom"
            style="z-index: 3"
            right
            transition="slide-y-reverse-transition"
          >
            <template v-slot:activator>
              <v-btn v-model="menuSubject" depressed color="primary" dark>
                <v-icon v-if="menuSubject">mdi-close</v-icon>
                <v-icon v-else>mdi-menu-down-outline</v-icon>
              </v-btn>
            </template>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :disabled="dataTable.length ? false : true"
                  depressed
                  fab
                  dense
                  small
                >
                  <download-excel
                    :data="dataTable"
                    :name="fileName()"
                    :fields="fields"
                  >
                    <v-icon color="primary">mdi-cloud-download</v-icon>
                  </download-excel>
                </v-btn>
              </template>
              <span>{{ $t("app.download") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  depressed
                  fab
                  dense
                  small
                  @click="toggleSubject"
                >
                  <v-icon color="primary">mdi-plus-box</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("subjects.subjects") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  depressed
                  fab
                  small
                  dense
                  @click="toggleMasterSubject"
                >
                  <v-icon color="primary">mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("subjects.master_subjects") }}</span>
            </v-tooltip>
          </v-speed-dial>
        </div>
      </div>

      <v-row v-else>
        <v-col cols="12" sm="12" md="7">
          <v-select
            v-model="idSchoolYear"
            :items="dataSchoolYear"
            :label="$t('subjects.school_year')"
            :loading="loadingSchoolYear"
            :item-text="item => schoolYearName(item)"
            item-value="id"
            outlined
            hide-details
            dense
            class="mr-2 select-225 caption"
            style="width: 150px; display: inline-flex"
            return-object
            @change="changeSchoolYear"
          >
            <template #item="{ item }">
              <span class="caption">
                {{ item.start_year }} / {{ item.end_year }}, semester
                {{ item.semester }}
              </span>
              <v-tooltip right>
                <template #activator="{ on }">
                  <v-icon
                    v-on="on"
                    :color="schoolYearInfo(item.status).color"
                    small
                    class="ml-1"
                  >
                    {{ schoolYearInfo(item.status).name }}
                  </v-icon>
                </template>
                <span>{{ schoolYearInfo(item.status).info }}</span>
              </v-tooltip>
            </template>
          </v-select>
          <v-select
            v-model="idClass"
            :items="dataClass"
            :label="$t('master_data.student.tab1_data.choose_class')"
            :loading="loadingClass"
            :disabled="loadingSchoolYear"
            :item-text="item => className(item)"
            item-value="id"
            outlined
            hide-details
            dense
            class="caption mr-2"
            style="width: 150px; display: inline-flex"
            @change="changeClass"
          />
          <v-btn
            :disabled="dataTable.length ? false : true"
            class="gradient-primary caption mr-2"
            depressed
            dark
            dense
          >
            <download-excel
              :name="fileName()"
              :data="dataTable"
              :fields="fields"
              class="d-inline"
            >
              {{ $t("app.download") }}
              <v-icon class="ml-1">mdi-cloud-download</v-icon>
            </download-excel>
          </v-btn>
          <div class="d-inline font-weight-bold subtitle-2">
            {{ dataTable.length }} {{ $t("academic.subjects_title") }}
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="5">
          <v-row no-gutters justify="end">
            <v-btn
              class="gradient-primary caption mr-2"
              dark
              dense
              depressed
              @click="toggleSubject"
            >
              {{ $t("subjects.subjects") }}
              <v-icon class="ml-1">mdi-plus-circle-outline</v-icon></v-btn
            >
            <v-btn
              class="gradient-primary caption mr-2"
              dark
              dense
              depressed
              @click="toggleMasterSubject"
            >
              {{ $t("subjects.master_subjects") }}
              <v-icon class="ml-1">mdi-plus-circle-outline</v-icon></v-btn
            >
          </v-row>
        </v-col>
      </v-row>

      <v-divider class="my-3" />

      <div v-if="$vuetify.breakpoint.mdAndDown" class="mb-4">
        <span class="font-weight-bold caption">
          {{ dataTable.length }} {{ $t("academic.subjects_title") }}
        </span>
      </div>

      <v-data-table
        :headers="tableHeaders"
        :items="dataTable"
        :loading="loading"
        item-key="id_lesson"
        fixed-header
        height="70vh"
        show-expand
        disable-pagination
        hide-default-footer
        class="elevation-0"
      >
        <template v-slot:item.lesson="{ item }">
          {{ item.lesson }} {{ item.sub }}
        </template>
        <template v-slot:item.action="{ item }">
          <div v-if="isTeacher ? showAction(item) : true">
            <v-btn
              :disabled="loading"
              :loading="item.loading"
              icon
              small
              dark
              class="primary mr-2"
              @click="toggleEdit(item)"
            >
              <v-icon small>mdi-pencil-box-outline</v-icon>
            </v-btn>
            <v-btn
              :disabled="loading"
              icon
              small
              dark
              class="error"
              @click="isDialogDelete(item)"
            >
              <v-icon small>mdi-delete-forever</v-icon>
            </v-btn>
          </div>
        </template>
        <template #item.data-table-expand="{ item, expand, isExpanded }">
          <v-btn
            v-if="item.teacher.length > 0"
            :disabled="loading"
            icon
            @click="expand(!isExpanded)"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td v-if="item.teacher.length > 0" :colspan="headers.length">
            <v-simple-table class="my-4">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("subjects.teacher_name") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(d, idx) in item.teacher" :key="`${idx}-item`">
                    <td>
                      <div>
                        {{ d.name }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
          <td v-else :colspan="headers.length">
            <v-row class="my-4" no-gutters justify="center">
              <span>{{ $t("app.no_data") }}</span>
            </v-row>
          </td>
        </template>
      </v-data-table>

      <v-bottom-sheet v-model="sheet">
        <v-sheet class="text-center pa-6">
          <v-row justify="center">
            <v-col cols="12" sm="6">
              <v-select
                v-model="idSchoolYear"
                :items="dataSchoolYear"
                :label="$t('subjects.school_year')"
                :loading="loadingSchoolYear"
                :item-text="item => schoolYearName(item)"
                item-value="id"
                outlined
                hide-details
                dense
                return-object
                @change="changeSchoolYear"
              >
                <template #item="{ item }">
                  <span class="caption">
                    {{ item.start_year }} / {{ item.end_year }}, semester
                    {{ item.semester }}
                  </span>
                  <v-tooltip right>
                    <template #activator="{ on }">
                      <v-icon
                        v-on="on"
                        :color="schoolYearInfo(item.status).color"
                        small
                        class="ml-1"
                        >{{ schoolYearInfo(item.status).name }}</v-icon
                      >
                    </template>
                    <span>{{ schoolYearInfo(item.status).info }}</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="idClass"
                :items="dataClass"
                :label="$t('master_data.student.tab1_data.choose_class')"
                :loading="loadingClass"
                :disabled="loadingSchoolYear"
                :item-text="item => `${item.grade} / ${item.name}`"
                item-value="id"
                outlined
                hide-details
                dense
                @change="changeClass"
              />
            </v-col>
          </v-row>
        </v-sheet>
      </v-bottom-sheet>
      <DialogSubjects
        :open="modalDialogSubject"
        :toggle="toggleSubject"
        :dataSemester="dataSchoolYear"
        :dataGrade="dataGrade"
        :dataSubjects="dataSubjects"
      />
      <DialogMasterSubjects
        :open="modalDialogMasterSubjects"
        @toggle="toggleMasterSubject"
      />
      <DialogEdit
        :modal="modalEdit"
        :subjectList="dataSubjects"
        :itemSubjects="itemSubjects"
        :schoolYearList="dataSchoolYear"
        :idSchoolYear="idSchoolYear"
        :teacherList="teacherList"
        :loading="loadingEdit"
        :studentsList="studentsDataList"
        :tempStudent="tempStudentEdit"
        @close="closeModalEdit"
        @save="submitEdit"
        @getStudents="students => (tempStudentEdit = students)"
      />
      <ModalConfirm
        :close="() => (this.confirmDelete = false)"
        :loading="loadingConfirm"
        :isOpen="confirmDelete"
        :save="deleteItem"
      />
    </v-card-text>
    <DialogForceDelete
      :subject="subjectName"
      :className="nameClass"
      :subjectsConflict="subjectsConflict"
      :modal="confirmForceDelete"
      :bodyDelete="bodyDelete"
      @close="
        () => {
          confirmForceDelete = false;
          getDataTable();
        }
      "
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { get_school_year_list } from "@/api/admin/schoolClass";
import {
  data_tiers,
  class_list,
  get_teacher
} from "@/api/admin/academic/classList";
import {
  getSubjectsList,
  getSubjects,
  submitEditSubject,
  deleteSubjects
} from "@/api/admin/academic/subjects";
import i18n from "@/i18n";
import { studentsList } from "@/api/admin/master/student";

export default {
  metaInfo: {
    title: i18n.t("routes.subjects"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    DialogSubjects: () => import("./DialogSubjects"),
    DialogMasterSubjects: () => import("./DialogMasterSubjects"),
    DialogEdit: () => import("./components/DialogEdit"),
    DialogForceDelete: () => import("@/components/DialogForceDelete"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  created() {
    this.initData();
  },
  computed: {
    ...mapGetters(["g_master_data_siswa"]),
    idSchoolYear: {
      set(value) {
        this.schoolYearId = value.id;
      },
      get() {
        return this.schoolYearId;
      }
    }
  },
  data() {
    return {
      sheet: false,
      schoolYearId: 0,
      dataSubjects: [],
      dataClass: [],
      dataGrade: [],
      dataSchoolYear: [],
      studentsDataList: [],
      itemSchoolYear: {},
      isTeacher: this.$store.getters.g_role_type === "TEACHER",
      personTeacher: this.$store.getters.g_user.person,
      modalEdit: false,
      loadingClass: false,
      loadingSchoolYear: false,
      modalDialogSubject: false,
      modalDialogMasterSubjects: false,
      loadingEdit: false,
      menuSubject: false,
      loading: false,
      confirmForceDelete: false,
      subjectName: "",
      nameClass: "",
      subjectsConflict: {
        elearnings: 0,
        employeeJournals: 0,
        lessonSchedules: 0
      },
      confirmDelete: false,
      loadingConfirm: false,
      length: 0,
      total: 0,
      fields: {
        [i18n.t("subjects.subjects")]: "lesson",
        [i18n.t("subjects.kkm")]: "kkm",
        [i18n.t("subjects.hours_week")]: "hours_week",
        [i18n.t("subjects.teacher")]: {
          field: "teacher",
          callback: value => value.map(d => d.name).join(" | ")
        }
      },
      tableHeaders: [
        {
          text: i18n.t("subjects.subjects"),
          value: "lesson"
        },
        {
          text: i18n.t("subjects.kkm"),
          value: "kkm"
        },
        {
          text: i18n.t("subjects.hours_week"),
          value: "hours_week",
          align: "center"
        },
        {
          text: i18n.t("subjects.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      dataTable: [],
      bodyClass: {
        filter: {
          "classroom.school_year_master": []
        },
        sort: "class_masters.id",
        order: "ASC"
      },
      bodyDataTable: {
        filter: {
          classroom: [],
          school_year: []
        }
      },
      idClass: "",
      itemSubjects: {},
      teacherList: [],
      bodyDelete: { id: 0, class: 0 },
      tempStudentEdit: []
    };
  },
  methods: {
    showAction(item) {
      return item.teacher.some(v => v.person === this.personTeacher);
    },
    fileName() {
      const schoolYear = this.schoolYearName(this.itemSchoolYear, "-");
      const className =
        this.idClass &&
        this.className(
          this.dataClass.find(item => item.id === this.idClass),
          "-"
        );
      return `${this.$i18n.t("subjects.subjects")} ${this.$i18n.t(
        "app.school_year"
      )} ${schoolYear} ${this.$i18n.t("app.class")} ${className}.xls`;
    },
    className: (item, separator) =>
      `${item.grade} ${separator || "/"} ${item.name}`,
    schoolYearName: (item, separator) =>
      item
        ? `${item.start_year} ${separator || "/"} ${item.end_year}, Semester ${
            item.semester
          }`
        : "",
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case 1:
          icon = active;
          break;
        case 2:
          icon = willCome;
          break;
      }
      return icon;
    },
    isDialogDelete(item) {
      this.bodyDelete.id = item.id_lesson;
      this.bodyDelete.class = item.classroom;
      this.confirmDelete = !this.confirmDelete;
      this.subjectName = item.lesson;
    },
    deleteItem() {
      this.loadingConfirm = true;
      deleteSubjects(this.bodyDelete)
        .then(res => {
          const code = res.data.code;
          if (code) {
            this.confirmDelete = false;
            const infoSubjects = res.data.data;
            switch (code) {
              case 1:
                this.getDataTable();
                this.snackBar(
                  true,
                  i18n.t("subjects.msg_response.subjects.success_deleted")
                );
                break;
              case 2:
                this.confirmForceDelete = true;
                this.subjectsConflict.elearnings = infoSubjects.elearnings;
                this.subjectsConflict.employeeJournals =
                  infoSubjects.employee_journals;
                this.subjectsConflict.lessonSchedules =
                  infoSubjects.lesson_schedules;
                break;
            }
          } else {
            this.snackBar(false, res.data.message);
          }
          this.loadingConfirm = false;
        })
        .catch(err => {
          console.error("deleteItem()\n", err);
          this.loadingConfirm = false;
        });
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    submitEdit() {
      let body = {
        id: this.itemSubjects.id_lesson,
        class: this.idClass,
        teacher: this.itemSubjects.teacher.map(item => item.id),
        hour_week: this.itemSubjects.hours_week,
        kkm: this.itemSubjects.kkm,
        student: this.tempStudentEdit.map(item => item.student)
      };
      this.loadingEdit = true;
      submitEditSubject(body)
        .then(() => {
          this.loadingEdit = false;
          this.modalEdit = false;
          this.snackBar(
            true,
            i18n.t("subjects.msg_response.subjects.success_edited")
          );
          this.getDataTable();
        })
        .catch(err => {
          this.snackBar(false, err);
          this.loadingEdit = false;
        });
    },
    closeModalEdit() {
      this.modalEdit = false;
      this.getDataTable();
    },
    async toggleEdit(item) {
      if (item) {
        this.itemSubjects = item;
        // this.teacherList = [...item.teacher];
        // type = 0 || 1
        await get_teacher().then(res => (this.teacherList = res.data.data));

        if (item.type) {
          item.loading = true;
          const idSchoolYearMaster = this.dataSchoolYear.find(
            item => item.id === this.idSchoolYear
          ).school_year_master;
          const body = {
            filter: {
              "class_students.class": [item.classroom],
              "class_students.school_year": [idSchoolYearMaster],
              "class_masters.id": [item.id_grade]
            }
          };

          try {
            const response = await studentsList(body);
            if (response.data.code) {
              const results = response.data.data;
              this.tempStudentEdit = results.filter(result =>
                item.student.includes(result.student)
              );
              this.studentsDataList = results;
            }
          } catch (error) {
            console.log("toggleEdit()", error);
          }
          item.loading = false;
        }
      }
      this.modalEdit = true;
    },
    changeClass() {
      this.bodyDataTable.filter.classroom = [this.idClass];
      this.bodyDataTable.filter.school_year = [this.itemSchoolYear.id];
      this.nameClass = this.className(
        this.dataClass.find(item => item.id === this.idClass),
        "-"
      );
      this.getDataTable();
    },
    changeSchoolYear(item) {
      this.itemSchoolYear = item;
      this.bodyClass.filter["classroom.school_year_master"] = [
        item.school_year_master
      ];
      this.classes();
    },
    initData() {
      this.schoolYear();
      this.grade();
      this.getDataSubjects();
      this.dataTable = [];
    },
    getDataSubjects() {
      getSubjects().then(res => {
        this.dataSubjects = res.data.data;
      });
    },
    getDataTable() {
      this.loading = true;
      getSubjectsList(this.bodyDataTable)
        .then(res => {
          this.dataTable = res.data.data.map(item => ({
            ...item,
            loading: false
          }));
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    classes() {
      this.loadingClass = true;
      class_list(this.bodyClass).then(res => {
        this.dataClass = res.data.data;
        this.loadingClass = false;
        if (this.dataClass.some(item => item.id === this.idClass)) {
          this.bodyDataTable.filter.classroom = [this.idClass];
          this.bodyDataTable.filter.school_year = [this.idSchoolYear];
          this.getDataTable();
        }
      });
    },
    grade() {
      data_tiers().then(res => {
        this.dataGrade = res.data.data;
      });
    },
    schoolYear() {
      this.loadingSchoolYear = true;
      get_school_year_list(false).then(res => {
        let r = res.data.data;
        let itemSchoolYear = r.find(d => d.status == 1);
        this.itemSchoolYear = itemSchoolYear;
        this.dataSchoolYear = r;
        if (itemSchoolYear) {
          this.idSchoolYear = itemSchoolYear;
          this.bodyClass.filter["classroom.school_year_master"] = [
            itemSchoolYear.school_year_master
          ];
          this.classes();
        }
        this.loadingSchoolYear = false;
      });
    },
    paginateChange(page, limit) {
      this.page = page;
      this.limit = limit;
      this.initData();
    },
    toggleSubject() {
      if (
        this.modalDialogSubject &&
        this.dataClass.some(item => item.id === this.idClass)
      ) {
        this.getDataTable();
      }
      this.modalDialogSubject = !this.modalDialogSubject;
    },
    toggleMasterSubject() {
      if (this.modalDialogMasterSubjects) {
        this.getDataSubjects();
        if (this.dataClass.some(item => item.id === this.idClass)) {
          this.getDataTable();
        }
      }
      this.modalDialogMasterSubjects = !this.modalDialogMasterSubjects;
    }
  }
};
</script>
